<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>归档项目</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          label="搜索项目"
          v-model="q"
          dense
          flat
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
      >
        <template v-slot:item.start="props">{{
          props.item.start | moment("YYYY-MM-DD")
        }}</template>
        <template v-slot:item.op="props">
          <v-tooltip bottom v-if="profile.role == 1">
            <template v-slot:activator="{ on }">
              <v-btn
                color="success"
                small
                icon
                v-on="on"
                v-if="props.item.accountid != 1"
                @click.native.stop="archiveProject(props.item.projectid)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>取消归档</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有项目被找到</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  data() {
    return {
      loading: true,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },

      data: {},

      headers: [
        {
          text: "ID",
          value: "projectid",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "项目名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "项目类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "负责人",
          value: "charger",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "开始时间",
          value: "start",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "项目周期(月)",
          value: "term",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        // {
        //   text: "操作",
        //   value: "op",
        //   align: "center",
        //   sortable: false,
        //   class: "data-table-header",
        // },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchProjects();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchProjects();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    ...mapState(useUserStore, ["profile"]),
  },
  methods: {
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchProjects();
      }
    },
    findUsers() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchProjects();
      }
    },
    fetchProjects() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `projects?pagesize=${this.pagination.rowsPerPage}&pageindex=${
            this.pagination.page
          }&archived=true&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    archiveProject(id) {
      this.$confirm("确定要取消归档这个项目吗?").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`projects?pid=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchProjects();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
