<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="800px"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="random-string"
      class="dialogForm"
    >
      <v-card
        outlined
        tile
      >
        <v-toolbar
          color="blue lighten-4"
          dense
        >
          <v-toolbar-title class="text-subtitle-1">案卷存放申请</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="归档年度"
                  dense
                  v-model="config.archive.archived_year"
                  :rules="validators.archived_year"
                  hint="年度数字"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="全宗号"
                  dense
                  v-model="config.archive.rootno"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="目录号"
                  dense
                  v-model="config.archive.catelogno"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  label="实体分类号"
                  dense
                  v-model="config.archive.entity_catelog_no"
                  @click.stop="openCategoryDialog"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  hide-details="true"
                  item-text="name"
                  item-value="value"
                  :items="systemDataGroups['生态环境要素']"
                  v-model="config.archive.huanjingyaosuleimu"
                  label="生态环境要素"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  hide-details="true"
                  item-text="name"
                  item-value="value"
                  :items="systemDataGroups['保管期限']"
                  v-model="config.archive.keepterm"
                  label="保管期限"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="案卷号"
                  dense
                  v-model="config.archive.volume_no"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="hasrule ? (customrule ? 9 : 12) : 12">
                <v-text-field
                  label="档号"
                  dense
                  v-model="config.archive.archive_no"
                  :hint="rulesHint"
                  :disabled="hasrule"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col
                cols="3"
                v-if="customrule"
              >
                <v-text-field
                  label="自定义档号字段"
                  dense
                  v-model="config.archive.customfield"
                  :rules="validators.customfield"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="题名"
                  v-model="config.archive.name"
                  :rules="validators.name"
                  dense
                  ref="__name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="主题词"
                  dense
                  v-model="config.archive.title"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-menu
                  ref="fileStartDialog"
                  v-model="fileStartDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedfileStartDate"
                      label="文件开始时间"
                      readonly
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="config.archive.file_date_start"
                    no-title
                    @input="fileStartDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="fileEndDialog"
                  v-model="fileEndDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedfileEndDate"
                      label="文件结束时间"
                      readonly
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="config.archive.file_date_end"
                    no-title
                    @input="fileEndDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="archiveDateDialog"
                  v-model="archiveDateDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedarchiveDate"
                      label="归档日期"
                      readonly
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="config.archive.archived_date"
                    no-title
                    @input="archiveDateDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  label="总件数"
                  dense
                  v-model="config.archive.file_count"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="总页数"
                  dense
                  v-model="config.archive.page_count"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  hide-details="true"
                  item-text="name"
                  item-value="value"
                  :items="systemDataGroups['密级']"
                  v-model="config.archive.sercet_level"
                  label="密级"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="机构"
                  dense
                  v-model="config.archive.organization"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="存放位置"
                  dense
                  v-model="config.archive.location"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="备注"
                  dense
                  v-model="config.archive.note"
                  :rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="立卷人"
                  dense
                  v-model="config.archive.createby"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="createdDateDialog"
                  v-model="createdDateDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedcreatedDate"
                      label="立卷时间"
                      readonly
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="config.archive.created_date"
                    no-title
                    @input="createdDateDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="输入人"
                  dense
                  v-model="config.archive.charger"
                  :rules="validators.name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="检查人"
                  dense
                  v-model="config.archive.verifiedby"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="verifiedDateDialog"
                  v-model="verifiedDateDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedverifiedDate"
                      label="检查时间"
                      readonly
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="config.archive.verified_date"
                    no-title
                    @input="verifiedDateDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="4"
                v-if="config.type==0"
              >
                <v-file-input
                  label="选择档案清单文件(Excel)"
                  @change="getFile($event)"
                  v-model="config.archive._pdf"
                  dense
                  :rules="validators.file"
                  accept=".xls,.xlsx"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="open = false"
          >取消</v-btn>

          <v-btn
            color="warning"
            :disabled="!valid"
            @click="createOrEdit(false)"
          >保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <category-tree-select-dialog
      ref="_category_dialog"
      :cateno.sync="config.archive.entity_catelog_no"
    ></category-tree-select-dialog>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "ArchiveRequestCreateDialog",
  data() {
    return {
      open: false,
      loading: false,
      title: "",
      config: {
        archive: {
          customfield: "",
        },
        rules: [],
      },
      valid: false,
      archive_no_col: 12,
      fileStartDialog: false,
      fileEndDialog: false,
      archiveDateDialog: false,
      createdDateDialog: false,
      verifiedDateDialog: false,
      systemDataGroups: [],
      validators: {
        archived_year: [(v) => /^\d{4,4}$/.test(v) || "请填写4位年度数字"],
        name: [(v) => !!v || "请填写题名"],
        charger: [(v) => !!v || "请填写输入人"],
        customfield: [(v) => !!v || "请填写自定义字段"],
        file: [(v) => !!v || "请选择上传文件"],
      },
    };
  },
  mounted() {
    this.fetchSystemDataGroup();
  },
  computed: {
    ...mapState(useUserStore, ["enums"]),
    hasrule() {
      if (this.config.rules && this.config.rules.length) {
        return true;
      }
      return false;
    },
    customrule() {
      if (this.hasrule) {
        for (var i = 0; i < this.config.rules.length; i++) {
          if (this.config.rules[i].ruletype === "自定义") {
            return true;
          }
        }
      }
      return false;
    },
    formatedfileStartDate() {
      return this.config.archive && this.config.archive.file_date_start
        ? this.$moment(
            this.config.archive.file_date_start,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY-MM-DD")
        : "";
    },
    formatedfileEndDate() {
      return this.config.archive && this.config.archive.file_date_end
        ? this.$moment(
            this.config.archive.file_date_end,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY-MM-DD")
        : "";
    },
    formatedarchiveDate() {
      return this.config.archive && this.config.archive.archived_date
        ? this.$moment(
            this.config.archive.archived_date,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY-MM-DD")
        : "";
    },
    formatedcreatedDate() {
      return this.config.archive && this.config.archive.created_date
        ? this.$moment(
            this.config.archive.created_date,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY-MM-DD")
        : "";
    },
    formatedverifiedDate() {
      return this.config.archive && this.config.archive.verified_date
        ? this.$moment(
            this.config.archive.verified_date,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY-MM-DD")
        : "";
    },
    rulesHint() {
      if (this.config.rules && this.config.rules.length) {
        var val = "";
        for (var i = 0; i < this.config.rules.length; i++) {
          var r = this.config.rules[i];
          val = val + `{${r.ruletype}:${r.length}${r.separator}}`;
        }
        return val;
      }
      return "";
    },
  },
  watch: {
    config: {
      handler() {
        if (
          this.open &&
          this.config &&
          this.config.archive &&
          this.config.rules &&
          this.config.rules.length
        ) {
          this.processRules();
        }
      },
      deep: true,
    },
  },
  methods: {
    openDialog(config) {
      this.config = config;
      if (this.config.type === 0) {
        this.title = "新建案卷";
      } else {
        this.title = "编辑案卷";
      }
      this.processRules(true);
      this.open = true;
    },
    openCategoryDialog() {
      this.$refs._category_dialog.openDialog();
    },
    fetchSystemDataGroup() {
      this.$hc
        .req()
        .get(`systemdata/groups?type=systemdictionary`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.systemDataGroups = x.Data;
          },
          (x) => {}
        );
    },
    getDisplayOfDisplayLevel(m) {
      if (
        this.systemDataGroups &&
        this.systemDataGroups["密级"] &&
        this.systemDataGroups["密级"].length
      ) {
        for (var i = 0; i < this.systemDataGroups["密级"].length; i++) {
          var d = this.systemDataGroups["密级"][i];
          if (d.value == m) {
            return d.name;
          }
        }
      }
      return m;
    },
    processRules(init) {
      var val = "";
      var current_val = this.config.archive.archive_no;
      if (
        this.config &&
        this.config.archive &&
        this.config.rules &&
        this.config.rules.length
      ) {
        for (var i = 0; i < this.config.rules.length; i++) {
          var r = this.config.rules[i];
          switch (r.ruletype) {
            case "全宗号":
              {
                if (this.config.archive.rootno) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.rootno,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "归档年度":
              {
                if (this.config.archive.archived_year) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.archived_year,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "密级":
              {
                if (this.config.archive.sercet_level) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.getDisplayOfDisplayLevel(
                        this.config.archive.sercet_level
                      ),
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "目录号":
              {
                if (this.config.archive.catelogno) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.catelogno,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "案卷号":
              {
                if (this.config.archive.volume_no) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.volume_no,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "实体分类号":
              {
                if (this.config.archive.entity_catelog_no) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.entity_catelog_no,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "保管期限":
              {
                if (this.config.archive.keepterm) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.keepterm,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "题名":
              {
                if (this.config.archive.name) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.name,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "归档日期":
              {
                if (this.config.archive.archived_date) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.archived_date,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;
            case "机构":
              {
                if (this.config.archive.organization) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.organization,
                      r.length
                    )}${r.separator}`;
                }
              }
              break;

            case "自定义":
              {
                if (this.config.archive.customfield) {
                  val =
                    val +
                    `${this.$g.fixStringLn(
                      this.config.archive.customfield,
                      r.length
                    )}${r.separator}`;
                }
                if (init && current_val) {
                  current_val = current_val.substr(val.length);

                  var idx = current_val.indexOf(r.separator);
                  if (idx > 0) {
                    current_val = current_val.substr(0, idx);
                    this.config.archive.customfield = current_val;
                  } else {
                    this.config.archive.customfield = current_val;
                  }
                }
              }
              break;
          }
        }
        if (val && !init) {
          val = val.replace(/(^-*)|(-*$)/g, "");
          this.config.archive.archive_no = val;
        }
      }
    },
    createOrEdit(next) {
      if (!this.$refs.form.validate()) return;
      this.$hc
        .req()
        .post(`archive/requests`, this.config.archive)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.open = false;
            this.$ns.cast("snack", {
              text: "档案存放请求已创建",
              color: "success",
            });
          },
          (x) => {}
        );
    },
    getFile(evt) {
      var files = event.target.files;
      if (files && files.length > 0) {
        var file = files[0];
        this.config.archive.filename = file.name;
        this.config.archive.name = file.name.substr(0, file.name.length - 4);
        var reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");
          this.config.archive.filebase64 = base64String;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
