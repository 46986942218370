<template>
  <v-app id="inspire">
    <v-app-bar app clipped-left dark class="indigo darken-4">
      <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title><h3>河北省生态环境监测中心档案管理系统</h3></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click.stop="logout">
            <v-icon>logout</v-icon>
          </v-btn>
        </template>
        <span>登出系统</span>
      </v-tooltip>
    </v-app-bar>
    <router-view></router-view>

    <v-snackbar
      v-model="msg.open"
      :bottom="!msg.top"
      :color="msg.color || 'success'"
      :left="msg.left"
      :multi-line="msg.ml"
      :right="msg.right"
      :timeout="msg.timeout || 5000"
      :top="msg.top"
      :vertical="false"
    >
      {{ msg.text }}
      <template v-slot:action="{ attrs }">
        <v-btn x-small text v-bind="attrs" @click="msg.open = false"> x </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="loading" class="lock">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapWritableState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "Landing",

  data: () => ({
    loading: false,
    msg: {
      open: false,
      timeout: 5000,
      color: "success",
      left: false,
      right: false,
      ml: false,
      top: true,
      text: "",
    },
  }),
  computed: {
    ...mapWritableState(useUserStore, ["sidebar"]),
  },
  methods: {
    logout() {
      const userStore = useUserStore();
      userStore.logout();
      if (this.$route.path != "/login") {
        this.$router.replace("/login");
      }
    },
  },
  beforeMount() {
    this.$ns.on("snack").subscribe((x) => {
      var opt = {
        open: false,
        timeout: 5000,
        color: "success",
        left: false,
        right: false,
        ml: false,
        top: true,
        text: "",
      };
      Object.assign(opt, x.data);
      this.msg = opt;
      this.msg.open = true;
    });
    this.$ns.on("lock").subscribe((x) => {
      this.loading = x.data;
    });
    this.$ns.on("login").subscribe(() => {
      if (!this.ldSet.show) {
        this.ldSet.show = true;
      }
    });
  },
};
</script>
<style scoped>
.lock {
  z-index: 999 !important;
}
</style>
