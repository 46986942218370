import notification from "./notification";
import isArray from "lodash/isArray";

export default {
  install: (Vue) => {
    var g = {};
    g.treeFindOne = function (id, items) {
      var i = 0,
        found;
      for (; i < items.length; i++) {
        if (items[i].id === id) {
          return items[i];
        } else if (isArray(items[i].children)) {
          found = g.treeFindOne(id, items[i].children);
          if (found) {
            return found;
          }
        }
      }
    };
    g.treeRoot = function (id, items) {
      var r = g.treeFindOne(id, items);
      while (r && r.parentid) {
        r = g.treeFindOne(r.parentid, items);
      }
      return r;
    };
    g.fixStringLn = function (str, length) {
      if ((length || 0) == 0) return str;
      if (str.length > length) return str.substr(0, length);
      if (/^\d+$/.test(str)) return str.padStart(length, "0");
      return str;
    };
    Vue["ns"] = notification;
    Vue.prototype.$ns = notification;
    window.$ns = notification;
    Vue["g"] = g;
    Vue.prototype.$g = g;
    window.$g = g;
  },
};
