<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="data.name"
                :rules="rules.name"
                :counter="50"
                maxlength="50"
                label="进度名称"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="data.requirement"
                :rules="rules.requirement"
                label="具体要求"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-menu
                ref="startDialog"
                v-model="startDialog"
                persistent
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatedDate"
                    label="预计完成时间"
                    prepend-icon="event"
                    :rules="rules.estimate"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.estimate"
                  no-title
                  @input="startDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 v-if="data.id">
              <v-select
                v-model="data.status"
                :items="progressStatusTypeList"
                :rules="rules.status"
                item-text="name"
                item-value="name"
                label="进度状态"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                name="hack-chrome"
                label="备注"
                :rows="3"
                v-model="data.note"
                autocomplete="false"
                :counter="500"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)">取消</v-btn>
          <v-btn color="warning" :disabled="!valid" @click="createOrEdit()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "ProgressEditDialog",
  props: ["data", "open", "subprojectid"],
  data: () => ({
    valid: false,
    startDialog: false,

    rules: {
      name: [
        (v) => !!v || "请填写进度名称",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) || "进度名称长度必须在2到50个字符之间",
      ],
      requirement: [(v) => !!v || "请填写具体要求"],
      estimate: [(v) => !!v || "请选择预计完成时间"],
      status: [(v) => !!v || "请选择进度状态"],
    },
  }),
  computed: {
    ...mapState(useUserStore, ["enums"]),
    title() {
      if (this.data && this.data.id) {
        return "修改进度";
      }
      return "新建进度";
    },
    progressStatusTypeList() {
      if (this.enums && this.enums.ProgressStatus) {
        return this.enums.ProgressStatus;
      }
      return [];
    },
    formatedDate() {
      return this.data && this.data.estimate
        ? this.$moment(this.data.estimate, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD")
        : "";
    },
  },
  methods: {
    createOrEdit() {
      if (!this.$refs.form.validate()) return;
      var data = { ...this.data };
      data.subprojid = this.subprojectid;
      if (this.data && this.data.id) {
        this.$hc
          .req()
          .put(`progress`, data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(`progress`, data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
