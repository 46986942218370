<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="random-string"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="data.name"
                :rules="rules.name"
                :counter="50"
                maxlength="50"
                label="项目名称"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="data.lat"
                :rules="rules.lat"
                label="纬度(wgs84)"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="data.lon"
                :rules="rules.lon"
                label="经度(wgs84)"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                name="hack-chrome"
                label="备注"
                :rows="3"
                v-model="data.note"
                autocomplete="false"
                :counter="500"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)"
            >取消</v-btn
          >
          <v-btn color="warning" :disabled="!valid" @click="createOrEdit()"
            >确定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "ProjectEditDialog",
  props: ["data", "open", "projectid"],
  data: () => ({
    valid: false,
    startDialog: false,

    rules: {
      name: [
        (v) => !!v || "请填写子项目名称",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) ||
          "子项目名称长度必须在2到50个字符之间",
      ],
      lat: [(v) => !v || !isNaN(v * 1) || "请填写正确的纬度"],
      lon: [(v) => !v || !isNaN(v * 1) || "请填写正确的经度"],
    },
  }),
  computed: {
    title() {
      if (this.data && this.data.subprojectid) {
        return "修改子项目";
      }
      return "新建子项目";
    },
  },
  methods: {
    createOrEdit() {
      if (!this.$refs.form.validate()) return;
      var data = { ...this.data };
      data.projectid = this.projectid;
      if (this.data && this.data.subprojectid) {
        this.$hc
          .req()
          .put(`subprojects`, data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(`subprojects`, data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
