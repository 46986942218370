var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"100%"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("档案审核")]),_c('v-spacer')],1)],1),_c('v-card',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticStyle:{"margin-top":"5px","overflow":"hidden"},attrs:{"height":_vm.containerHeight,"flat":""}},[_c('v-container',{staticClass:"minhight100",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{style:({
            height: _vm.containerHeight / 2 + 'px',
            'padding-top': '0px',
            'padding-right': '5px',
            'padding-left': '5px',
          }),attrs:{"cols":"12"}},[_c('c-archives',{attrs:{"canedit":false,"cancreate":false,"candelete":false,"canupload":false,"canimport":false,"verify-filter":true,"verify":true,"toolbar":"true","vh":_vm.containerHeight / 2}})],1)],1),_c('v-row',{staticStyle:{"padding-top":"0px","margin-top":"0px"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{style:({
                height: _vm.containerHeight / 2 - 10 + 'px',
                'padding-top': '0px',
                'padding-bottom': '0px',
                'padding-right': '5px',
                'padding-left': '5px',
              })},[_c('c-documents',{attrs:{"canedit":false,"candelete":false,"vh":_vm.containerHeight / 2}})],1)],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',{style:({
                height: _vm.containerHeight / 2 + 'px',
                'padding-top': '0px',
                'padding-bottom': '0px',
                'padding-right': '5px',
                'padding-left': '5px',
              })},[_c('c-records',{attrs:{"canedit":false,"cancreate":false,"candelete":false,"canupload":false,"canimport":false,"vh":_vm.containerHeight / 2}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }