<template>
  <v-sheet style="padding: 0" :height="mapheight">
    <l-map ref="map" :zoom="mapOptions.zoom" :center="center">
      <l-china-tilelayer
        :layerName="mapOptions.layerName"
        :options="mapOptions.options"
      />
      <l-marker v-for="p in points" :lat-lng="[p.lat, p.lon]" v-bind:key="p.subprojectid">
        <l-popup style="width: 200px">
          <v-card flat>
            <v-card-title style="white-space: nowrap">{{ p.projectname }}</v-card-title>
            <v-card-text
              >{{ p.subprojectname }} <br />
              <a href="http://hb.dev.peng.work/" target="_blank">全景</a></v-card-text
            >
          </v-card>
        </l-popup>
        <l-icon :icon-size="[15, 20]">
          <img :src="iconUrl" style="width: 15px; height: 20px" />
          <div class="marker-title">{{ p.subprojectname }}</div>
        </l-icon>
      </l-marker>
    </l-map>
  </v-sheet>
</template>

<script>
export default {
  name: "SubProjectMapTab",
  props: ["id", "type", "mapheight"],
  data: () => ({
    mapOptions: {
      zoom: 12,
      layerName: "Geoq.Normal.Map", //options see https://www.npmjs.com/package/leaflet.chinatmsproviders
      options: {
        maxZoom: 18,
        minZoom: 7,
      },
      center: [32.06031863553111, 118.76315116882326],
    },
    iconUrl:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=",
    points: [],
    center: window.L.latLng(0, 0),
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      var q = {};
      if (this.type == "project") {
        q.projectid = this.id;
      } else {
        q.subprojectid = this.id;
      }
      this.$hc
        .req()
        .post(`timeline/search`, q)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.points = x.Data.MapPoints;
            if (this.points && this.points.length) {
              this.center = window.L.latLng(this.points[0].lat, this.points[0].lon);
            }

            // window.$map = this.$refs.map.mapObject;
            // this.$refs.map.mapObject.panTo(
            //   window.L.latLng(x.Data.CenterLat, x.Data.CenterLon)
            // );
          },
          (x) => {}
        );
    },
  },
};
</script>
