import { defineStore } from "pinia";
import Vue from "vue";

export const useFolderStore = defineStore("folders", {
  state: () => ({
    active_folders: [],
    data: [],
    loading: false,
    openids:[]
  }),
  getters: {
    selected_folder: (state) => {
      if (state.active_folders && state.active_folders.length) {
        return state.active_folders[0];
      }
      return 0;
    },
    get_root_folder_name: (state) => {
      if (state.selected_folder) {
        var f = Vue.g.treeRoot(state.selected_folder, state.data);
        if (f) {
          return f.name;
        }
      }
      return "";
    },
  },
  actions: {
    loadData(id) {
      this.loading = true;
      Vue.hc
        .req()
        .get(`folders`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.data = x.Data.Tree;
            if (!this.openids || this.openids.length==0){
              this.openids=x.Data.open;
            }
            if (id) {
              this.active_folders = [id];
            }
            this.loading = false;
          },
          (x) => {
            this.loading = false;
          }
        );
    },
    createOrUpdate(vm) {
      this.loading = true;
      if (vm.id) {
        Vue.hc
          .req()
          .put(`folders`, vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.loadData(this.selected_folder);
            },
            (x) => {
              this.loading = false;
            }
          );
      } else {
        Vue.hc
          .req()
          .post(`folders`, {
            name: vm.name,
            note: vm.note,
            orderindex: vm.orderindex,
            rules: vm.rules,
            parentid: this.selected_folder ?? null,
          })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.loadData(this.selected_folder);
            },
            (x) => {
              this.loading = false;
            }
          );
      }
    },
    delete() {
      this.loading = true;
      Vue.hc
        .req()
        .delete(`folders/${this.selected_folder}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.active_folders = [];
            this.loadData(0);
          },
          (x) => {
            this.loading = false;
          }
        );
    },
    getSelectedNodeCopy() {
      if (this.selected_folder) {
        var f = Vue.g.treeFindOne(this.selected_folder, this.data);
        var fs = JSON.stringify(f);
        return JSON.parse(fs);
      }
      return null;
    },
  },
});
