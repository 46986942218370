<template>
  <v-card :height="mh" outlined>
    <v-toolbar dense>
      <v-spacer></v-spacer>
      <v-btn x-small @click="choosFiles">选择文件</v-btn>
      <v-btn x-small @click="clearFiles">清除文件</v-btn>
      <input
        type="file"
        ref="_file_input"
        multiple
        style="display: none"
        @change="onFileChange"
      />
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :height="mh - 70"
        :fixed-header="true"
        dense
        :headers="headers"
        hide-default-footer
        disable-pagination
        :server-items-length="files.length"
        :items="files"
        :sort-by.sync="sorting.sortby"
        :sort-desc.sync="sorting.sortdesc"
        :item-class="itemClass"
      >
        <template v-slot:item.time="props">{{
          props.item.time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "CMultiFileSelector",
  props: ["mh"],
  data() {
    return {
      files: [],

      sorting: {
        sortby: "",
        sortdesc: "",
      },
      headers: [
        {
          text: "文件名(原始)",
          value: "oriname",
          align: "center",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "文件名(新)",
          value: "name",
          align: "center",
          sortable: false,
        },
        {
          text: "时间",
          value: "time",
          align: "center",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "",
          value: "deleted",
          align: "center",
          sortable: true,
          class: "data-table-header",
        },
      ],
    };
  },

  mounted() {},
  watch: {
    sorting: {
      handler() {
        this.files.sort((a, b) => {
          var i = a[this.sorting.sortby] > b[this.sorting.sortby] ? 1 : -1;
          return this.sorting.sortdesc ? -i : i;
        });

        this.$emit("filechange");
      },
      deep: true,
    },
  },
  methods: {
    itemClass() {
      return "text-no-wrap";
    },
    choosFiles() {
      this.$refs._file_input.click();
    },
    clearFiles() {
      this.$refs._file_input.value = null;
      this.files = [];
    },
    onFileChange(evt) {
      var files = event.target.files;
      if (files && files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          this.files.push({
            oriname: files[i].name,
            name: files[i].name,
            time: files[i].lastModified,
            file: files[i],
          });
        }
      }
      //this.$emit("filechange");
    },
  },
};
</script>
<style scoped>
.file-form {
  height: 50px;
  width: 100%;
  background: #a5b8e2;
  margin: auto;
  text-align: center;
  line-height: 50px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
