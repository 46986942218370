<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card outlined tile>
        <v-toolbar color="blue lighten-4" dense>
          <v-toolbar-title class="text-subtitle-1">{{ title }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="目录名称"
            v-model="config.folder.name"
            :rules="validators.name"
          ></v-text-field>
          <v-text-field
            label="目录顺序"
            v-model="config.folder.orderindex"
            :rules="validators.orderindex"
          ></v-text-field>
          <v-textarea label="备注" v-model="config.folder.note" :rows="2"></v-textarea>
          <c-rule-editor :item="config.folder"></c-rule-editor>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="open = false">取消</v-btn>
          <v-btn color="warning" @click="createOrEdit()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useFolderStore } from "@/stores/folderStore.js";
export default {
  name: "FolderEditDialog",
  props: [],
  data() {
    return {
      loading: false,
      valid: false,
      open: false,
      title: "",
      config: {
        type: 0,
        folder: {
          id: 0,
          rules: [],
        },
      },
      validators: {
        name: [(v) => !!v || "请填写目录名称"],
        orderindex: [(v) => /^\d*$/.test(v || "0") || "请填写数字"],
      },
    };
  },
  computed: {
    ...mapState(useUserStore, ["enums"]),
  },
  methods: {
    openDialog(config) {
      this.config = config;
      if (config.type == 0) {
        if (config && config.folder && config.folder.id) {
          this.title = "新建目录";
        } else {
          this.title = "新建根目录";
        }
      } else {
        this.title = "编辑目录";
      }
      this.open = true;
    },
    createOrEdit() {
      if (!this.validateForm()) return;
      if (this.config.type == 0) {
        this.addFoleder();
      } else {
        this.editFoleder();
      }
    },
    validateForm() {
      if (!this.config.folder.name) {
        this.$ns.cast("snack", {
          text: "请填写目录名称",
          color: "error",
        });
        return false;
      }
      if (this.config.folder.rules && this.config.folder.rules.length) {
        for (var i = 0; i < this.config.folder.rules.length; i++) {
          var r = this.config.folder.rules[i];
          if (r.length.length > 0) {
            if (!/\d+/.test(r.length)) {
              this.$ns.cast("snack", {
                text: "规则长度必须为数字类型",
                color: "error",
              });
              return false;
            }
          }
        }
      }
      return true;
    },
    editFoleder() {
      const store = useFolderStore();
      store.createOrUpdate(this.config.folder);
      this.open = false;
    },
    addFoleder() {
      const store = useFolderStore();
      store.createOrUpdate({
        name: this.config.folder.name,
        note: this.config.folder.note,
        rules: this.config.folder.rules,
        orderindex: this.config.folder.orderindex,
        parentid: this.config.folder.id,
      });
      this.open = false;
    },
  },
};
</script>
