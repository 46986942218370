<template>
  <div class="minhight100">
    <v-data-table
      :headers="headers"
      :items="data"
      :server-items-length="totalrecords"
      :loading="loading"
      hide-default-footer
      item-key="id"
      :fixed-header="true"
      single-select
      v-model="selected_documents"
      @click:row="rowClick"
      dense
      :height="vh - 90"
      class="table-selectable"
    >
      <template v-slot:item.createdtime="props">{{
        props.item.createdtime | moment("YYYY-MM-DD")
      }}</template>

      <template v-slot:item.download="props">
        <v-tooltip bottom v-if="view_document_permission">
          <template v-slot:activator="{ on }">
            <v-btn
              color="green"
              small
              icon
              v-on="on"
              :href="`${attachmenturl}${props.item.filepath}`"
              target="_blank"
            >
              <v-icon small>remove_red_eye</v-icon>
            </v-btn>
          </template>
          <span>打开文件</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>没有文档</template>
    </v-data-table>
    <div class="text-xs-center pt-2">
      <v-pagination
        v-model="pageindex"
        :length="get_total_pages"
        :total-visible="15"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import fileDownload from "js-file-download";
import Axios from "axios";
import { mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useDocumentStore } from "@/stores/documentStore.js";
import { useArchiveStore } from "@/stores/archiveStore.js";
export default {
  name: "CDocumentList",
  props: ["vh"],
  data() {
    return {
      headers: [
        {
          text: "文件名",
          value: "name",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "责任人",
          value: "charger",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "备注",
          value: "note",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "查看",
          value: "download",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  computed: {
    ...mapState(useUserStore, ["profile"]),
    ...mapState(useArchiveStore, ["archive_permission", "view_document_permission"]),
    ...mapState(useDocumentStore, [
      "pagesize",
      "get_total_pages",
      "totalrecords",
      "data",
      "loading",
      "active_document",
    ]),
    ...mapWritableState(useDocumentStore, ["pageindex", "selected_documents"]),
    apiroot() {
      return process.env.VUE_APP_API;
    },
    attachmenturl() {
      return process.env.VUE_APP_DOC_ROOT;
    },
  },
  watch: {
    pageindex: {
      handler() {
        const store = useDocumentStore();
        store.loadData();
      },
    },
  },
  mounted() {},
  methods: {
    rowClick(item, row) {
      row.select(true);
      if (this.active_document && this.active_document.id == item.id) {
        row.select(false);
        this.selected_documents = [];
      } else {
        row.select(true);
      }
    },

    download(id) {
      this.$ns.cast("lock", true);
      const tk = this.$ls.get("token", null, String);
      let u = JSON.parse(tk);
      Axios({
        method: "get",
        url: `${this.apiroot}archivedocuments/download?id=${id}`,
        headers: {
          Authorization: `Bearer ${u.Token}`,
        },
        responseType: "blob",
      })
        .then((x) => {
          var filename = x.request.getResponseHeader("pd-name");
          if (!filename) {
            this.$ns.cast("snack", {
              text: "没有权限下载文档",
              color: "error",
            });
          } else {
            fileDownload(x.data, decodeURI(filename));
          }
        })
        .finally(() => this.$ns.cast("lock", false));
    },
  },
};
</script>
