var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"label":"搜索子项目","dense":"","flat":"","hide-details":"","append-icon":'search'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();return _vm.search()},"click:append":function($event){return _vm.search()}},model:{value:(_vm.q),callback:function ($$v) {_vm.q=$$v},expression:"q"}}),_c('v-spacer'),(_vm.inrole([1, 3]))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.current = {};
          _vm.dialog = true;}}},[_vm._v("新建子项目")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data.Payload,"server-items-length":_vm.data.Total,"loading":_vm.loading,"hide-default-footer":"","item-key":"id","fixed-header":true,"dense":""},scopedSlots:_vm._u([{key:"item.createdtime",fn:function(props){return [_vm._v(_vm._s(_vm._f("moment")(props.item.createdtime,"YYYY-MM-DD")))]}},{key:"item.estimate",fn:function(props){return [_vm._v(_vm._s(_vm._f("moment")(props.item.estimate,"YYYY-MM-DD")))]}},{key:"item.op",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","icon":"","to":`/subprojects/${props.item.subprojectid}/details`}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('span',[_vm._v("查看子项目")])]),(
            _vm.inrole([1, 3]) || (_vm.profile.role == 4 && props.item.createdby == _vm.profile.id)
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"orange","small":"","icon":""},on:{"click":function($event){_vm.current = { ...props.item };
                _vm.dialog = true;}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("修改子项目")])]):_vm._e(),(
            _vm.inrole([1, 3]) || (_vm.uprofile.role == 4 && props.item.createdby == _vm.profile.id)
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(props.item.accountid != 1)?_c('v-btn',_vm._g({attrs:{"color":"error","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteData(props.item.subprojectid)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("删除子项目")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v("没有子项目")]},proxy:true}])}),_c('div',{staticClass:"text-xs-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":10},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1),_c('sub-project-edit-dialog',{attrs:{"data":_vm.current,"open":_vm.dialog,"projectid":_vm.projectid},on:{"update:open":function($event){_vm.dialog=$event},"success":_vm.fetchData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }