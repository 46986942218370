<template>
  <v-card>
    <v-system-bar color="blue lighten-5">
      档号规则
      <v-spacer></v-spacer>
      <v-btn x-small color="green" @click="addRule" dark>添加规则</v-btn>
    </v-system-bar>
    <v-data-table
      dense
      :headers="headers"
      :items="item.rules"
      disable-pagination
      hide-default-footer
      :fixed-header="true"
    >
      <template v-slot:item.ruletype="props">
        <v-select
          dense
          hide-details="true"
          item-text="name"
          item-value="name"
          :items="enums.RuleTypes"
          v-model="props.item.ruletype"
          outlined
          style="padding: 5px 12px"
        ></v-select>
      </template>
      <template v-slot:item.length="props">
        <v-text-field
          dense
          v-model="props.item.length"
          hide-details="true"
          :rules="validator.number"
        ></v-text-field>
      </template>
      <template v-slot:item.separator="props">
        <v-text-field
          dense
          v-model="props.item.separator"
          hide-details="true"
        ></v-text-field>
      </template>
      <template v-slot:item.op="props">
        <v-btn icon color="error" small @click="deleteRule(props.item.id)"
          ><v-icon small>delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "PRuleEditor",
  props: ["item"],
  data() {
    return {
      validator: {
        number: [(v) => !v || /\d+/.test(v) || "请输入数字"],
      },
      headers: [
        {
          text: "序号",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "80",
        },
        {
          text: "类型",
          value: "ruletype",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "长度",
          value: "length",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "80",
        },
        {
          text: "分隔符",
          value: "separator",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "80",
        },
        {
          text: "删除",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "120",
        },
      ],
    };
  },
  computed: {
    ...mapState(useUserStore, ["enums"]),
  },
  methods: {
    addRule() {
      var id = this.item.rules.length + 1;
      this.item.rules.push({
        id: id,
        ruletype: "全宗号",
        length: 0,
        separator: "-",
      });
    },
    deleteRule(rule) {
      this.item.rules.splice(rule - 1, 1);
      for (var i = 0; i < this.item.rules.length; i++) {
        this.item.rules[i].id = i + 1;
      }
    },
  },
};
</script>
