<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="800px"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="random-string"
    >
      <v-card
        outlined
        tile
      >
        <v-toolbar
          color="blue lighten-4"
          dense
        >
          <v-toolbar-title>借阅申请 </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  label="借阅人"
                  dense
                  :value="profile.name"
                  disabled
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="借阅部门"
                  dense
                  v-model="vm.borrow_department"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-menu
                  ref="borrowDateDialog"
                  v-model="borrowDateDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedborrowDate"
                      label="借阅时间"
                      readonly
                      dense
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vm.borrow_date"
                    no-title
                    @input="borrowDateDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-menu
                  ref="returnDateDialog"
                  v-model="returnDateDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedreturnDate"
                      label="归还日期"
                      readonly
                      dense
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vm.return_date"
                    no-title
                    @input="returnDateDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="档号"
                  dense
                  v-model="vm.doc_no"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  label="题名"
                  dense
                  v-model="vm.doc_title"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="借阅目的"
                  dense
                  v-model="vm.borrow_reason"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <c-account-auto-complete
                  label="部门负责人"
                  :aid.sync="vm.charger_dep_id"
                  :validaterules="rules.department"
                  ref="_aac1"
                ></c-account-auto-complete>
              </v-col>
              <v-col cols="3">
                <c-account-auto-complete
                  label="技术负责人"
                  :aid.sync="vm.charger_tech_id"
                  ref="_aac2"
                ></c-account-auto-complete>
              </v-col>
              <v-col cols="3">
                <c-account-auto-complete
                  label="质量负责人"
                  :aid.sync="vm.charger_quantity_id"
                  ref="_aac3"
                ></c-account-auto-complete>
              </v-col>
              <v-col cols="3">
                <c-account-auto-complete
                  label="中心领导"
                  :aid.sync="vm.leader_id"
                  ref="_aac4"
                ></c-account-auto-complete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="open = false"
          >取消</v-btn>
          <v-btn
            color="warning"
            @click="createOrEdit()"
          >确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
  
  <script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "CreateBorrowRequestDialog",
  data() {
    return {
      open: false,
      borrowDateDialog: false,
      returnDateDialog: false,
      loading: false,
      valid: false,
      vm: {
        borrow_department: "",
        borrow_date: this.$moment().format("YYYY-MM-DD"),
        borrow_reason: "",
        doc_type: 0,
        archive_id: 0,
        record_id: 0,
        doc_title: "",
        doc_no: "",
        charger_dep_id: 0,
        charger_tech_id: 0,
        charger_quantity_id: 0,
        leader_id: 0,
        return_date: this.$moment().format("YYYY-MM-DD"),
      },
      rules: {
        department: [(v) => !!v || "请选择部门负责人"],
      },
    };
  },
  computed: {
    formatedborrowDate() {
      return !this.vm.borrow_date
        ? this.$moment().format("YYYY-MM-DD")
        : this.vm.borrow_date;
    },
    formatedreturnDate() {
      return this.vm && this.vm.return_date
        ? this.$moment(this.vm.return_date, "YYYY-MM-DD").format("YYYY-MM-DD")
        : this.$moment().format("YYYY-MM-DD");
    },
    ...mapState(useUserStore, ["profile"]),
  },
  methods: {
    openDialog(type, aid, rid, title, no) {
      this.$refs._aac1 && this.$refs._aac1.clear();
      this.$refs._aac2 && this.$refs._aac2.clear();
      this.$refs._aac3 && this.$refs._aac3.clear();
      this.$refs._aac4 && this.$refs._aac4.clear();
      this.vm = {
        borrow_department: "",
        borrow_date: this.$moment().format("YYYY-MM-DD"),
        borrow_reason: "",
        doc_type: 0,
        archive_id: 0,
        record_id: 0,
        doc_title: "",
        doc_no: "",
        charger_dep_id: 0,
        charger_tech_id: 0,
        charger_quantity_id: 0,
        leader_id: 0,
        return_date: this.$moment().format("YYYY-MM-DD"),
      };
      this.vm.doc_type = type;
      this.vm.archive_id = aid;
      this.vm.record_id = rid;
      this.vm.doc_title = title;
      this.vm.doc_no = no;
      this.open = true;
    },
    createOrEdit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.$hc
        .req()
        .post(`borrow/requests`, this.vm)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.open = false;
            this.$ns.cast("snack", {
              text: "借阅请求已创建",
              color: "success",
            });
          },
          (x) => {
            this.loading = false;
          }
        );
    },
  },
};
</script>
  