<template>
  <div>
    <v-card outlined>
      <v-toolbar color="blue-grey  lighten-5" dense>
        <v-toolbar-title class="text-subtitle-1">档案目录</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="cancreate">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon v-on="on" @click="openCreateFolderDialog" small>
              <v-icon>create_new_folder</v-icon>
            </v-btn>
          </template>
          <span>新建目录</span>
        </v-tooltip>
        <v-tooltip bottom v-if="canedit">
          <template v-slot:activator="{ on }">
            <v-btn
              color="green"
              small
              icon
              v-on="on"
              @click.native.stop="openEditFolderDialog"
              :disabled="!selected_folder"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>编辑目录</span>
        </v-tooltip>
        <v-tooltip bottom v-if="candelete">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="error"
              icon
              v-on="on"
              @click.native.stop="deleteFoleder"
              :disabled="!selected_folder"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>删除目录</span>
        </v-tooltip>
      </v-toolbar>
      <c-folder-tree :vh="vh"></c-folder-tree>
    </v-card>
    <folder-edit-dialog ref="_folder_dialog"></folder-edit-dialog>
  </div>
</template>
<script>
import { mapState, mapWritableState } from "pinia";
import { useFolderStore } from "@/stores/folderStore.js";
export default {
  name: "ArchiveFolders",
  props: ["vh", "cancreate", "canedit", "candelete"],
  data() {
    return {};
  },
  computed: {
    ...mapState(useFolderStore, ["selected_folder"]),
    ...mapWritableState(useFolderStore, ["active_folders"]),
  },
  mounted() {},
  methods: {
    openCreateFolderDialog() {
      var config = {
        type: 0,
        folder: {
          id: 0,
          rules: [],
        },
      };
      const folderStore = useFolderStore();
      if (folderStore.selected_folder) {
        config.folder.id = folderStore.selected_folder;
      }
      this.$refs._folder_dialog.openDialog(config);
    },
    openEditFolderDialog() {
      var config = {
        type: 0,
        folder: {
          id: 0,
          rules: [],
        },
      };
      config.type = 1;
      const folderStore = useFolderStore();
      config.folder = folderStore.getSelectedNodeCopy();
      this.$refs._folder_dialog.openDialog(config);
    },
    deleteFoleder() {
      const folderStore = useFolderStore();
      if (!folderStore.selected_folder) return;
      this.$confirm("确定要删除这个目录吗? 该目录及其子目录都将被删除").then((x) => {
        if (x) {
          folderStore.delete();
        }
      });
    },
  },
};
</script>
