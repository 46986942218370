<template>
  <div :style="{ height: myheight+60 + 'px', overflow: 'auto' }">
    <v-data-table
      @click:row="rowClick"
      :headers="headers"
      :items="data"
      :server-items-length="totalrecords"
      :loading="loading"
      :options.sync="sort_options"
      multi-sort
      hide-default-footer
      item-key="id"
      :fixed-header="true"
      dense
      single-select
      v-model="selected_archives"
      :height="myheight"
      class="table-selectable"
      @update:sort-by="fetchData"
      @update:sort-desc="fetchData"
    >
      <template v-slot:item.BorrowStatus="props">
        <v-tooltip
          bottom
          v-if="props.item.BorrowStatus>0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :class="{'red--text':props.item.BorrowStatus==1,'orange--text':props.item.BorrowStatus==2}"
              dark
              v-bind="attrs"
              v-on="on"
              small
            >
              circle
            </v-icon>
          </template>
          <span>{{props.item.BorrowStatus==1?"整卷借出":"档案借出"}}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.sercet_level="props">{{
        getDisplayOfDisplayLevel(props.item.sercet_level)
      }}</template>
      <template v-slot:item.jieyue="props">
        <v-btn
          @click.stop="openBorrowDialog(0,props.item.id,0,props.item.name, props.item.archive_no)"
          x-small
          class="primary"
        >借阅</v-btn>
      </template>
      <template v-slot:item.verified="props">
        <span v-if="!verify">{{ props.item.verified ? "已审核" : "未审核" }}</span>
        <v-btn
          @click.stop="switchVerify(props.item.id)"
          class="warning"
          v-if="verify && props.item.verified"
          x-small
        >取消</v-btn>
        <v-btn
          @click.stop="switchVerify(props.item.id)"
          class="success"
          v-if="verify && !props.item.verified"
          x-small
        >通过</v-btn>
      </template>
    </v-data-table>
    <div class="text-xs-center pt-2">
      <v-pagination
        v-model="pageindex"
        :length="get_total_pages"
        :total-visible="15"
      ></v-pagination>
    </div>
    <create-borrow-request-dialog ref="_borrowDialog"></create-borrow-request-dialog>
  </div>
</template>
<script>
import { mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useFolderStore } from "@/stores/folderStore.js";
import { useArchiveStore } from "@/stores/archiveStore.js";
export default {
  name: "CArchiveList",
  props: ["vh", "verify", "fullscreen"],
  data() {
    return {
      systemDataGroups: { 密级: [] },
      headers: [
        {
          text: "",
          value: "BorrowStatus",
          align: "center",
          sortable: false,
          class: "data-table-header padding-lr3",
          width: "5",
        },
        {
          text: "档号",
          value: "archive_no",
          align: "left",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "归档年度",
          value: "archived_year",
          align: "left",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "目录号",
          value: "catelogno",
          align: "left",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "案卷号",
          value: "volume_no",
          align: "left",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "题名",
          value: "name",
          align: "left",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "实体分类号",
          value: "entity_catelog_no",
          align: "left",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "密级",
          value: "sercet_level",
          align: "left",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "保管期限",
          value: "keepterm",
          align: "left",
          sortable: true,
          class: "data-table-header",
        },
        {
          text: "备注",
          value: "note",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "借阅",
          value: "jieyue",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "审核状态",
          value: "verified",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  computed: {
    ...mapState(useUserStore, ["profile"]),
    ...mapState(useFolderStore, ["selected_folder"]),
    ...mapState(useArchiveStore, [
      "pagesize",
      "get_total_pages",
      "q",
      "totalrecords",
      "data",
      "loading",
      "active_archive",
    ]),
    ...mapWritableState(useArchiveStore, ["pageindex", "selected_archives","sort_options"]),
    myheight() {
      if (this.fullscreen) {
        return window.innerHeight + this.vh - this.vh - 50;
      } else {
        return this.vh - 125;
      }
    },
  },
  watch: {
    pageindex: {
      handler() {
        this.fetchData();
      },
    },
  },
  mounted() {
    this.fetchSystemDataGroup();
  },
  methods: {
    fetchData() {
      const archiveStore = useArchiveStore();
      archiveStore.loadData(true, null);
    },
    getDisplayOfDisplayLevel(m) {
      if (
        this.systemDataGroups &&
        this.systemDataGroups["密级"] &&
        this.systemDataGroups["密级"].length
      ) {
        for (var i = 0; i < this.systemDataGroups["密级"].length; i++) {
          var d = this.systemDataGroups["密级"][i];
          if (d.value == m) {
            return d.name;
          }
        }
      }
      return m;
    },
    fetchSystemDataGroup() {
      this.$hc
        .req()
        .get(`systemdata/groups?type=systemdictionary`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.systemDataGroups = x.Data;
          },
          (x) => {}
        );
    },
    rowClick(item, row) {
      if (this.active_archive && this.active_archive.id == item.id) {
        row.select(false);
        this.selected_archives = [];
      } else {
        row.select(true);
      }
    },
    switchVerify(id) {
      const store = useArchiveStore();
      store.switchVerify(id);
    },
    openBorrowDialog(type, aid, rid, title, no) {
      this.$refs._borrowDialog.openDialog(type, aid, rid, title, no);
    },
  },
};
</script>
