<template>
  <div>
    <v-card
      outlined
      :class="{'fullscreen':fullscreen}"
    >
      <v-toolbar
        color="blue-grey  lighten-5"
        dense
      >
        <v-toolbar-title class="text-subtitle-1">档案</v-toolbar-title>
        <v-spacer></v-spacer>
        <div style="width: 300px">
          <v-text-field
            label="搜索 (文件编号,文件题名)"
            v-model="q"
            dense
            flat
            class="mr-10 ft1rem"
            hide-details
            :append-icon="'search'"
            @keyup.enter.stop="search()"
            @click:append="search()"
            style="margin-top:5px;"
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              icon
              small
              v-on="on"
              @click="switchFullscreen"
            >
              <v-icon>{{fullscreen?"fullscreen_exit":"fullscreen"}}</v-icon>
            </v-btn>
          </template>
          <span>{{fullscreen?"退出全屏":"全屏模式"}}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="canimport"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              icon
              v-on="on"
              @click="openImportDialog"
              :disabled="!enable_create || !archive_permission"
            >
              <v-icon>save_alt</v-icon>
            </v-btn>
          </template>
          <span>批量导入档案</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="canupload"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              icon
              v-on="on"
              @click="openFileDialog"
              :disabled="!enable_upload || !archive_permission"
            >
              <v-icon>cloud_upload</v-icon>
            </v-btn>
          </template>
          <span>上传文件</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="cancreate"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              icon
              v-on="on"
              @click="openAddDialog"
              :disabled="!enable_create || !archive_permission"
            >
              <v-icon>add_circle</v-icon>
            </v-btn>
          </template>
          <span>新建档案</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="canedit"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="green"
              small
              icon
              v-on="on"
              @click="openEditDialog"
              :disabled="!active_record || !archive_permission"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>编辑档案</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="candelete"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="error"
              small
              icon
              v-on="on"
              @click="deleteArchive"
              :disabled="!active_record || !archive_permission"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>删除档案</span>
        </v-tooltip>
      </v-toolbar>
      <c-record-list
        ref="list"
        :vh="vh"
        :fullscreen="fullscreen"
      ></c-record-list>
    </v-card>
    <record-edit-dialog ref="_archive_dialog"></record-edit-dialog>
    <file-upload-dialog ref="_file_dialog"></file-upload-dialog>
    <input
      type="file"
      ref="_file_input"
      style="display: none"
      accept=".xls,.xlsx"
      @change="onFileChange"
    />
  </div>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useArchiveStore } from "@/stores/archiveStore.js";
import { useRecordStore } from "@/stores/recordStore.js";
import { useDocumentStore } from "@/stores/documentStore.js";
export default {
  name: "CRecords",
  props: ["vh", "cancreate", "canedit", "candelete", "canupload", "canimport"],
  data() {
    return {
      fullscreen: false,
      dialogs: {
        edit: {
          type: 0,
          open: false,
          archive: {},
          rules: [],
        },
      },
    };
  },
  computed: {
    ...mapState(useUserStore, ["profile"]),
    ...mapState(useArchiveStore, ["active_archive", "archive_permission"]),
    ...mapState(useRecordStore, {
      records: "data",
      active_record: "active_record",
    }),
    ...mapState(useDocumentStore, {
      documents: "data",
    }),
    ...mapWritableState(useRecordStore, ["q"]),
    enable_upload() {
      if (!this.active_record) return false;
      return true;
    },
    enable_create() {
      if (!this.active_archive || (this.documents && this.documents.length))
        return false;
      return true;
    },
  },
  methods: {
    openAddDialog() {
      var config = {
        type: 0,
        record: {
          page_no: 1,
          page_count: 1,
          order_index: 1,
          document_no: "",
          archiveid: this.active_archive.id,
        },
      };
      this.$refs._archive_dialog.openDialog(config);
    },
    openEditDialog() {
      var config = {
        type: 1,
        record: {
          ...this.active_record,
        },
      };
      this.$refs._archive_dialog.openDialog(config);
    },
    deleteArchive() {
      this.$confirm(
        "确定要删除这个案卷吗? 该案卷及其包含的文件都将被删除"
      ).then((x) => {
        if (x) {
          const store = useRecordStore();
          store.delete();
        }
      });
    },
    search() {
      const store = useRecordStore();
      store.pageindex = 1;
      store.loadData();
    },
    openFileDialog() {
      this.$refs._file_dialog.openDialog(0, this.active_record.id);
    },
    openImportDialog() {
      this.$refs._file_input.click();
    },
    onFileChange() {
      var file = event.target.files;
      if (file && file.length > 0) {
        const store = useArchiveStore();
        const recordStore = useRecordStore();
        store.import(file[0], 0, store.active_archive.id).subscribe(
          (x) => {
            recordStore.loadData();
          },
          (x) => {}
        );
        event.target.value = "";
      }
    },
    switchFullscreen(){
      const store = useRecordStore();
      this.fullscreen=!this.fullscreen;
      if(this.fullscreen){
        store.pagesize=0;
        store.loadData();
      }
      else{
        store.pagesize=10;
        store.loadData();
      }
    }
  },
};
</script>
