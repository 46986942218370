<template>
  <v-autocomplete
    v-model="userid"
    :items="accounts"
    :loading="isLoading"
    :search-input.sync="searchaccount"
    item-text="name"
    item-value="accountid"
    :label="label"
    flat
    clearable
    :filter="itemFilter"
    :rules="validaterules"
  >
    <template v-slot:item="data">
      <template>
        <v-list-item-content>
          <v-list-item-title>账号: {{ data.item.username }}</v-list-item-title>
          <v-list-item-subtitle> 姓名:{{ data.item.name }} </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
                    
<script>
import { mapState, mapWritableState } from "pinia";
import { useFolderStore } from "@/stores/folderStore.js";
import { BehaviorSubject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";
export default {
  name: "AccountAutoComplete",
  props: ["aid", "label", "validaterules"],
  data() {
    return {
      isLoading: false,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      accounts: [],
      userid: 0,
      searchaccount: null,
      obs: {
        search$: null,
        searchSub$: null,
      },
      data: [],
    };
  },
  watch: {
    searchaccount(val) {
      this.obs.search$.next(val);
    },
    userid(val) {
      this.$emit("update:aid", val);
    },
  },
  computed: {
    ...mapState(useFolderStore, ["selected_folder"]),
    ...mapWritableState(useFolderStore, ["active_folders"]),
  },
  mounted() {
    this.obs.search$ = new BehaviorSubject();
    this.obs.searchSub$ = this.obs.search$
      .asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((x) => x && x.length >= 1),
        switchMap((x) => {
          this.isLoading = true;
          return this.$hc
            .req()
            .get(
              `accounts?r=${this.role}&pagesize=10000&pageindex=1&q=${x || ""}`
            )
            .go({
              lock: false,
              toastError: true,
              toastSuccess: "",
            });
        })
      )
      .subscribe(
        (x) => {
          this.isLoading = false;
          this.accounts = x.Data.Payload;
        },
        (x) => {
          this.isLoading = true;
        }
      );
  },
  methods: {
    itemFilter() {
      return true;
    },
    clear() {
      this.userid = null;
    },
  },
};
</script>
