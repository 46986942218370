<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>用户信息</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          color="warning"
          style="margin-right: 10px"
          @click="
            dialogSet.info.data = {};
            dialogSet.open = true;
          "
          >修改密码</v-btn
        >
        <v-btn color="primary" @click="saveProfile">保存</v-btn>
      </v-toolbar>
    </v-card>
    <v-form
      ref="profileForm"
      v-model="user.valid"
      lazy-validation
      autocomplete="random-string"
      style="margin-top: 50px"
    >
      <v-container grid-list-md>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="user.username" label="用户名" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="user.name"
              label="姓名"
              :rules="rules.name"
              :counter="50"
              maxlength="50"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="user.contact"
              label="联系电话"
              :rules="rules.contact"
              :counter="50"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="user.company"
              label="工作单位"
              :counter="50"
              maxlength="50"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="user.department"
              label="部门"
              :counter="50"
              maxlength="50"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="user.title"
              label="职务"
              :counter="50"
              maxlength="50"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialogSet.open" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.info.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">修改密码</span>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  type="password"
                  v-model="dialogSet.info.data.old"
                  :rules="rules.oldMimaRules"
                  :counter="50"
                  maxlength="50"
                  label="旧密码"
                ></v-text-field>
                <v-text-field
                  type="password"
                  v-model="dialogSet.info.data.pass1"
                  :rules="rules.mimaRules"
                  :counter="50"
                  maxlength="50"
                  label="新密码"
                ></v-text-field>
                <v-text-field
                  type="password"
                  v-model="dialogSet.info.data.pass2"
                  :rules="[confirmPass]"
                  :counter="50"
                  maxlength="50"
                  label="确认新密码"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogSet.open = false">取消</v-btn>
            <v-btn
              color="warning"
              :disabled="!dialogSet.info.valid"
              @click="resetPassword(dialogSet.info.data)"
              >确定</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  data() {
    return {
      loading: true,
      user: {},
      rules: {
        oldMimaRules: [(v) => !!v || "请填写旧密码"],
        mimaRules: [
          (v) => !!v || "请填写密码",
          (v) =>
            (v && v.length <= 50 && v.length >= 6) || "密码长度必须在6到50个字符之间",
        ],
        username: [
          (v) => !!v || "请填写账号",
          (v) =>
            (v && v.length <= 50 && v.length >= 2) || "账号长度必须在2到50个字符之间",
        ],
        name: [
          (v) => !!v || "请填写姓名",
          (v) =>
            (v && v.length <= 50 && v.length >= 2) || "姓名长度必须在2到50个字符之间",
        ],
        contact: [
          (v) => !!v || "请填写联系方式",
          (v) => (v || "").length <= 50 || "联系方式必须少于50个字符",
        ],
      },
      dialogSet: {
        open: false,
        info: {
          valid: false,
          data: {},
        },
      },
    };
  },
  computed: {
    ...mapState(useUserStore, ["profile"]),
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.loading = true;
      this.$hc
        .req()
        .get(`accounts/profile`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.user = x.Data;
          },
          (x) => {}
        );
    },
    confirmPass(value) {
      if (!value) return "请填写确认新密码";
      return value === this.dialogSet.info.data.pass1 || "密码不一致";
    },

    saveProfile() {
      if (!this.$refs.profileForm.validate()) return;
      this.$hc
        .req()
        .put(`accounts`, { accountid: this.profile.id, ...this.user })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "用户信息已保存",
        })
        .subscribe(
          (x) => {
            this.loading = false;
          },
          (x) => {}
        );
    },
    resetPassword(data) {
      if (!this.$refs.addForm.validate()) return;
      this.$hc
        .req()
        .put(`accounts/pass`, {
          accountid: this.profile.id,
          ...data,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "密码修改成功",
        })
        .subscribe(
          (x) => {
            this.dialogSet.open = false;
          },
          (x) => {}
        );
    },
  },
};
</script>
