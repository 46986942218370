<template>
  <v-container style="padding: 0">
    <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item v-for="(p, i) in points" :key="i">
        <template v-slot:opposite>
          <span :class="`headline font-weight-bold`">{{
            p.estimate | moment("YYYY-MM-DD")
          }}</span>
        </template>
        <v-card class="blue lighten-5" outlined>
          <v-card-title>{{ p.subprojectname }}</v-card-title>
          <v-card-subtitle
            >{{ p.progressname }} - {{ p.status }}</v-card-subtitle
          >
          <v-card-text>{{ p.progressnote }}</v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
export default {
  name: "TimelineTab",
  props: ["id", "type"],
  data: () => ({
    points: [],
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      var q = {};
      if (this.type == "project") {
        q.projectid = this.id;
      } else {
        q.subprojectid = this.id;
      }
      this.$hc
        .req()
        .post(`timeline/search`, q)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.points = x.Data.TimelinePoints;
          },
          (x) => {}
        );
    },
  },
};
</script>