<template>
  <v-container fluid style="padding: 0">
    <v-toolbar flat class="darken-2 elevation-5">
      <v-breadcrumbs :items="breads" large></v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid>
      <v-card>
        <v-card-title>子项目详细信息</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">项目名称:</span>
              </v-col>
              <v-col cols="12" sm="2">
                <span>{{ data.name }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">经度:</span>
              </v-col>
              <v-col cols="12" sm="2">
                <span>{{ data.lon }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">纬度:</span>
              </v-col>
              <v-col cols="12" sm="2">
                <span>{{ data.lon }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">状态:</span>
              </v-col>
              <v-col cols="12" sm="2">
                <span>{{ data.status }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">预计完成时间:</span>
              </v-col>
              <v-col cols="12" sm="2">
                <span>{{ data.estimate | moment("YYYY-MM-DD") }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">备注:</span>
              </v-col>
              <v-col cols="12" sm="11">
                <span>{{ data.note }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-sheet outlined>
          <v-tabs background-color="blue-grey lighten-5">
            <v-tab @click="$refs.timeline && $refs.timeline.fetchData()">时间线</v-tab>
            <v-tab>文档</v-tab>
            <v-tab>进度</v-tab>
            <v-tab-item>
              <timeline-tab type="subproject" :id="id" ref="timeline"></timeline-tab>
            </v-tab-item>
            <v-tab-item>
              <document-tab :parentid="id" parenttype="subproject"></document-tab>
            </v-tab-item>
            <v-tab-item>
              <progress-tab :subprojectid="id"></progress-tab>
            </v-tab-item>
          </v-tabs>
        </v-sheet>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  props: ["id"],
  data: () => ({
    loading: false,
    breads: [
      {
        text: "项目管理",
        disabled: false,
        to: "/",
      },
      {
        text: "",
        disabled: false,
      },
      {
        text: "",
        disabled: true,
      },
    ],
    data: {},
  }),
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(`subprojects/${this.id}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
            this.breads[1].text = this.data.projectname;
            this.breads[1].to = `/projects/${this.data.projectid}/details`;
            this.breads[2].text = this.data.name;
          },
          (x) => {}
        );
    },
  },
};
</script>
